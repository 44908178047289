@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.hr-transition {
  transition: width 0.3s ease; /* Adjust the duration and timing function as needed */
}

.scroll-div {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="text"],
textarea {

  background-color: transparent;

}

.lineGradient {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;

  border-image-source: linear-gradient(90deg, rgba(128, 147, 255, 0) 0%, #7D9BFA 21.14%, #60DBD4 78.8%, rgba(92, 228, 207, 0) 100%);
}

.borderGradient {
  border: 1px solid transparent;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 2px;
  border-image-source: linear-gradient(to left, #7D9BFA, #60DBD4);


}

.radiant-text {
  background: conic-gradient(from 180deg at 50% 50%, #66FFE6 -160.13deg, #8093FF 42.37deg, #66FFE6 199.87deg, #8093FF 402.37deg),
    linear-gradient(0deg, #F4F4F4, #F4F4F4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.open .content {
  /* Define your styles for open content here */
  max-height: 1000px;
  /* Adjust to a suitable large value */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.zoom {
  transition: all 0.4s ease-in-out;
  /* Animation */
}

.zoom:hover {
  transform: scale(1.03);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.zoom-in {
  transition: transform .8s ease;
  /* Animation */
}

.zoom-in:hover {
  transform: scale(.97);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}